import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, InputGroup, OverlayTrigger, Popover, Tooltip, Badge } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'

import ViewCard from '../components/cards/ViewCard'

import {loadShelves, loadNotebookById} from '../actions/learnActions'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const NotebookViewScreen = ({match, history}) => {

    const dispatch = useDispatch()

    var notebook_id = match.params.url

    const [selectSession, setSelectedSession ] = useState('')

    

    const shelfnotebook = useSelector(state => state.shelfnotebook)
    const { title, cards } = shelfnotebook



    useEffect(() => {

        dispatch(loadNotebookById(notebook_id))

    }, [])

    var cardss = [
        {
            "_id":"asdsd3123sda",
            "type":"note",
            "title":"What are Optics?",
            "items":[
                {
                    "text":"Optics is the branch of physics that studies the behaviour and properties of light, including its interactions with matter and the construction of instruments that use or detect it.[1] Optics usually describes the behaviour of visible, ultraviolet, and infrared light."
                },
                {
                    "text":"Most optical phenomena can be accounted for by using the classical electromagnetic description of light, however complete electromagnetic descriptions of light are often difficult to apply in practice. Practical optics is usually done using simplified models."
                }
            ]

        },
        {
            "_id":"asdsd23d23sda",
            "type":"example",
            "title":"A Simple Optics Problem",
            "question":[],
            "answer":[],
            "explanation":[]
        },
        {
            "_id":"asdsd23d23sda",
            "type":"test",
            "title":"A Test on Matrices",
            "instructions": [],
            "questions":[
                {
                    "texts":[],
                    "options":[],
                    "answerMCQ":"",
                    "solution":[],
                    "explanation":[]
                }
            ],
    
        },
        {
            "_id":"asdsd23d23sda",
            "type":"video",
            "title":"A Test on Matrices",
            "source":"",
            "url":"",
            "description":[]
        },
        {
            "_id":"asdsd23d23sda",
            "type":"image",
            "title":"A Test on Matrices",
            "description":[],
            "url":"",
            "file":{}
        }
    ]




    return (

        <>

            <Meta title={'TeachYaar Notebook'} description={'Create Dynamic Interactive Course Modules and Course Notes for your lectures. Make the lessons interactive for your students.'}/>

            <Container className="p-2 p-md-4 mt-2 mt-md-5">


                <Card style={{borderRadius:'20px'}}>
                    <Card.Header style={{borderRadius:'20px'}}>
                        <p className='h4'>{title} </p>
                    </Card.Header>
                </Card>
                

                {cards && cards.map((icard, index)=>(
                    <ViewCard card_in={icard}/>
                ))}

            </Container>

         

  

        </>
    )
}


export default NotebookViewScreen
