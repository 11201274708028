import React , {useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, InputGroup, OverlayTrigger, Popover, Tooltip, Badge } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'

import PageTitle from '../components/cards/PageTitle'
import AddCardForm from '../components/cards/AddCardForm'
import EditCard from '../components/cards/EditCard'

import MainMenu from '../components/MainMenu'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";


export const NotebookScreen = ({match, history}) => {


    var course_id = match.params.course_id

    const [selectSession, setSelectedSession ] = useState('')

    const dispatch = useDispatch()

    const cardList = useSelector(state => state.cardList)
    const { cards } = cardList

    const notebookR = useSelector(state => state.notebookR)
    const { title, sessionMode } = notebookR



    useEffect(() => {



    }, [])

    var cardss = [
        {
            "_id":"asdsd3123sda",
            "type":"note",
            "title":"What are Optics?",
            "items":[
                {
                    "text":"Optics is the branch of physics that studies the behaviour and properties of light, including its interactions with matter and the construction of instruments that use or detect it.[1] Optics usually describes the behaviour of visible, ultraviolet, and infrared light."
                },
                {
                    "text":"Most optical phenomena can be accounted for by using the classical electromagnetic description of light, however complete electromagnetic descriptions of light are often difficult to apply in practice. Practical optics is usually done using simplified models."
                }
            ]

        },
        {
            "_id":"asdsd23d23sda",
            "type":"example",
            "title":"A Simple Optics Problem",
            "question":[],
            "answer":[],
            "explanation":[]
        },
        {
            "_id":"asdsd23d23sda",
            "type":"test",
            "title":"A Test on Matrices",
            "instructions": [],
            "questions":[
                {
                    "texts":[],
                    "options":[],
                    "answerMCQ":"",
                    "solution":[],
                    "explanation":[]
                }
            ],
    
        },
        {
            "_id":"asdsd23d23sda",
            "type":"video",
            "title":"A Test on Matrices",
            "source":"",
            "url":"",
            "description":[]
        },
        {
            "_id":"asdsd23d23sda",
            "type":"image",
            "title":"A Test on Matrices",
            "description":[],
            "url":"",
            "file":{}
        }
    ]




    return (

        <>

            <Meta title={'TeachYaar Notebook Creator'} description={'Create Dynamic Interactive Course Modules and Course Notes for your lectures. Make the lessons interactive for your students.'}/>

            <Container>

                <p>Do not refresh your screen</p>

                <MainMenu />

                <PageTitle />
                

                {cards && cards.map((icard, index)=>(
                    <EditCard card_in={icard}/>
                ))}


                {sessionMode == 'edit' && (
                    <>
                        <AddCardForm />
                    </>
                )}

                


            </Container>

         

  

        </>
    )
}


export default NotebookScreen
