// Author : Anil Variyar
// License : Proprietary
// Property of : Anil Variyar/ Pixagan Technologies Private Limited

import React from 'react'
import '../node_modules/video-react/dist/video-react.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Container } from 'react-bootstrap'


import NotebookScreen from './screens/NotebookScreen'
import ModulesScreen from './screens/ModulesScreen'
import NotebookViewScreen from './screens/NotebookViewScreen'
import LandingScreen from './screens/LandingScreen'

//import SmartLearnScreen from './screens/SmartLearnScreen'

import EulaScreen from './screens/EulaScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'


import Alert from './components/Alert'
import Header from './components/Header'
import Footer from './components/Footer'


const App = () => {
  return (

    <Router>
      <Header/>

      <Alert /> 
      <main className='py-3'>



        <Container fluid>

          <Switch>

          <Route path='/' component={LandingScreen} exact />

          <Route path='/create' component={NotebookScreen} exact />
          <Route path='/notebooks/:url' component={NotebookViewScreen} exact />

          <Route path='/notebooks' component={ModulesScreen} exact />

          {/* <Route path='/smartdoubts' component={SmartLearnScreen} exact />
          <Route path='/smartdoubts/:concept_url' component={SmartLearnScreen} exact /> */}

          <Route path='/privacypolicy' component={PrivacyPolicyScreen} />
          <Route path='/termsandconditions' component={EulaScreen} />

          <Route component={NotebookScreen} />

          </Switch>
        

        </Container>



        
      </main>
      <Footer />
    </Router>
  )
}

export default App


