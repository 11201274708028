import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { alertReducer } from './reducers/alertReducer'

import {cardListReducer, notebookReducer} from './reducers/cardReducer'

import {shelvesReducer, shelfnotebookReducer} from './reducers/shelfsReducer'

import {slcardListReducer, sltopicListReducer, learningPathReducer, slconceptnavReducer} from './reducers/smartlearnReducer'
import {qsListReducer, qsByIdReducer} from './reducers/qsReducer'


const reducer = combineReducers({

    alerts: alertReducer,
    cardList: cardListReducer,
    notebookR: notebookReducer,
    shelvesR: shelvesReducer,
    shelfnotebook: shelfnotebookReducer,

    qsList: qsListReducer,
    qsById: qsByIdReducer,

    slcardList: slcardListReducer, 
    sltopicList: sltopicListReducer,
    learningPath: learningPathReducer,
    slconceptnav: slconceptnavReducer,

})



//const userInfoItemsFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
const cardsFromStorage = sessionStorage.getItem('cards') ? JSON.parse(sessionStorage.getItem('cards')) : []
const notebookFromStorage = sessionStorage.getItem('notebook') ? JSON.parse(sessionStorage.getItem('notebook')) : []

const initialState = {
    cardList:{
        cards:cardsFromStorage
    },
    notebookR:{
        title:notebookFromStorage,
        sessionMode:'edit'
    }
} 

const middleware = [thunk]
const store = createStore(
    reducer, 
    initialState, 
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store